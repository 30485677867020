<template>
  <div>
    <v-breadcrumbs class="py-2" :items="breadcrumbs">
      <template v-slot:item="{ item }">
        <router-link :to="item.href" class="v-breadcrumbs__item">
          {{ item.text }}
        </router-link>
      </template>
    </v-breadcrumbs>
    <v-card outlined class="mt-1">
      <v-card-title class="pb-2 pt-2 pl-2 d-flex grey lighten-4">
        <span class="text-truncate">
          {{ record.issn + " - " + record.journal }}
        </span>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-row v-if="record._id" class="mt-3" dense>
          <v-col v-if="datacollection.labels" cols="12">
            <v-sheet height="200px">
              <chart-line :styles="myStyles" :chart-data="datacollection" :options="optionsIF" />
            </v-sheet>
          </v-col>
          <v-col cols="12">
            <v-sheet height="200px">
              <chart-bar :styles="myStyles" :chart-data="chartData" :options="options" />
            </v-sheet>
          </v-col>
          <v-col cols="12">
            <v-simple-table dense>
              <template #default>
                <thead>
                  <tr>
                    <th class="text-left">Year</th>
                    <th class="text-left">IF value</th>
                    <th class="text-left">AIS value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(index, i) in record.if_ais_val" :key="'indaif' + i">
                    <td>{{ index.year }}</td>
                    <td>{{ index.if }}</td>
                    <td>{{ index.ais }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <!-- <v-col v-if="chartData.series" cols="12">
            <v-sheet height="200px">
              <chartist color="info" :data="chartData" type="Bar" style="max-height: 150px;" />
            </v-sheet>
          </v-col> -->
          <v-col cols="12">
            <v-expansion-panels v-model="panel" multiple>
              <v-expansion-panel v-for="(scie, ind) in record.scie" :key="'cat' + ind">
                <v-expansion-panel-header class="text-subtitle-2 grey lighten-3">
                  {{ scie.category }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-simple-table dense>
                    <template #default>
                      <thead>
                        <tr>
                          <th class="text-left">Year</th>
                          <th class="text-left">IF quarter</th>
                          <th class="text-left">IF no order</th>
                          <th class="text-left">AIS quarter</th>
                          <th class="text-left">AIS no order</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(index, i) in scie.indices" :key="'ind' + i">
                          <td>{{ index.year }}</td>
                          <td>{{ index.if.quarter }}</td>
                          <td>{{ index.if.order_no }}</td>
                          <td>{{ index.ais.quarter }}</td>
                          <td>{{ index.ais.order_no }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import axios from "../../../plugins/axios"
import ChartBar from "../charts/Bar"
import ChartLine from "../charts/Line"
export default {
  name: "DashboardDashboard",
  components: { ChartBar, ChartLine },
  data() {
    return {
      issn: "",
      record: {},
      loading: true,
      optionsIF: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                stepSize: 1
              }
            }
          ]
        }
      },
      datacollection: {},

      chartData: {},
      panel: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      path: "",
      render: 1,
      breadcrumbs: [
        {
          text: "Home",
          disabled: false,
          href: "/"
        },
        {
          text: "Scientometrics",
          disabled: false,
          href: "/scientometrics"
        }
      ]
    }
  },
  computed: {
    myStyles() {
      return {
        height: "200px",
        position: "relative"
      }
    }
  },
  watch: {
    $route(to) {
      this.refreshPage()
    }
  },
  created() {
    this.$log("routec ", this.$route)
    this.refreshPage()
  },
  methods: {
    refreshPage() {
      if (this.$route.name == "ScientometricsJournal") {
        this.issn = this.$route.params.pathMatch
        this.panel = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]

        this.getRecords()
      }
    },
    getRecords() {
      axios
        .get('infuni_scientometrics?where={"issn":"' + this.issn + '"}')
        .then(response => {
          this.record = response.data._items[0]
          this.record.scie.map(itm => {
            itm.indices.sort((a, b) => {
              return b.year - a.year
            })
          })
          this.chartData = []
          const arr = []
          const lbls = []
          const ifais = [...this.record.if_ais_val]
          ifais.sort((a, b) => {
            return a.year - b.year
          })
          ifais.map(itm => {
            lbls.push(itm.year)
            arr.push(itm.if)
          })
          this.datacollection = {
            labels: lbls,
            datasets: [
              {
                label: "IF",
                borderColor: "#1565C0",
                fill: false,
                borderWidth: 2,
                barThickness: 10,
                data: arr
              }
            ]
          }
          const labels = []
          const ds = {}
          this.record.scie.map((scie, ind) => {
            ds[scie.category] = { if: {}, ais: {} }
            scie.indices.map(itm => {
              if (labels.indexOf(itm.year) < 0) {
                labels.push(itm.year)
              }
              ds[scie.category].if[itm.year] = itm.if.quarter
              ds[scie.category].ais[itm.year] = itm.ais.quarter
            })
          })
          labels.sort()
          this.$log("years ", labels)
          const datas = []
          const colors = [
            "#42A5F5",
            "#66BB6A",
            "#EF5350",
            "#FFA726",
            "#7E57C2",
            "#26A69A",
            "#5C6BC0",
            "#9CCC65",
            "#1565C0",
            "#C62828",
            "#9E9D24",
            "#00695C",
            "#2E7D32",
            "#00838F",
            "#558B2F",
            "#0277BD",
            "#283593",
            "#4527A0",
            "#283593",
            "#EF6C00",
            "#37474F",
            "#4E342E"
          ]
          let colorid = 0
          for (const cat in ds) {
            const arrif = []
            const arrais = []
            this.$log("cat ", cat)
            labels.map(itm => {
              arrif.push(ds[cat].if[itm])
              arrais.push(ds[cat].ais[itm])
            })
            datas.push({
              label: "IF " + cat,
              backgroundColor: colors[colorid++],
              borderWidth: 2,
              barThickness: 10,
              data: arrif
            })
            datas.push({
              label: "AIS " + cat,
              backgroundColor: colors[colorid++],
              borderWidth: 2,
              barThickness: 10,
              data: arrif
            })
          }

          this.chartData = {
            labels: labels,
            datasets: datas
          }

          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    getRandomColor() {
      var letters = "0123456789ABCDEF".split("")
      var color = "#"
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
      }
      return color
    },
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    }
  }
}
</script>
<style scoped>
.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>
